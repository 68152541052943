import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Core from './Core'
import styled from 'styled-components'
class Article extends React.Component {
  render() {
    const post = this.props.data.cosmicjsPosts
    const siteTitle = get(
      this.props,
      'data.cosmicjsSettings.metadata.site_title'
    )
    const author = get(this, 'props.data.cosmicjsSettings.metadata')
    const location = get(this, 'props.location')
    const { previous, next } = this.props.pageContext
    const ArticleWrapper = styled.main`
        color: ${props => props.theme.textColor};
        padding:.5em;
        text-align:left;
    `
    return (
      <Core>
        <Helmet>
          <title>Justin Isbell-{post.title}</title>
          <meta charSet="utf-8" />
          <meta name="description" content={post.metadata.description}></meta>
          <link rel="canonical" href={"https://www.justinisbell.org/posts/"+post.slug} />
        </Helmet>
        <div>
          <Link to="/content">← Back to my content!</Link>
        </div>
        <ArticleWrapper>
          <h1 className='article-title'>
            {post.title}
          </h1>
          <p className='article-date'>
            {post.created}
          </p>
          <div className="post-hero"/>
          <div className="post-content"  dangerouslySetInnerHTML={{ __html: post.content }}   />
        </ArticleWrapper>
        <hr

        />
      </Core>
    )
  }
}

export default Article

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    cosmicjsPosts(slug: { eq: $slug }) {
      id
      content
      title
      created(formatString: "MMMM DD, YYYY")
      slug
      metadata {
        hero {
          imgix_url
        }
        tag
        description
      }
    }
    cosmicjsSettings(slug: { eq: "general" }) {
      metadata {
        site_title
        author_name
        author_bio
        author_avatar {
          imgix_url
        }
      }
    }
  }
`